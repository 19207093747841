<template>
  <div class="row">
    <div class="col">
      <b-card title="Properties">
        <blueprint-data api-route-path="inventory/properties" default :fields="fields" :new-default-values="newDefaultValues">
          <template v-slot:address="{ item }">
            {{ item.street }}<span v-if="item.city && item.city !== '' || item.zip && item.zip !== ''">,</span>
            {{ item.city }} {{ item.zip }}<span v-if="item.state && item.state !== ''">,</span>
            {{ item.state }}<span v-if="item.country && item.country !== ''">,</span>
            {{ item.country }}
          </template>
          <template v-slot:default="{ state, item }">
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Name" label-for="street">
              <b-form-input id="name" v-model="item.name" :state="state('name')" type="text" placeholder="Name" />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Check-out time" label-for="checkOut">
              <timepicker v-model="item.checkOut" :state="state('checkOut')" />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Check-in time" label-for="checkIn">
              <timepicker v-model="item.checkIn" :state="state('checkIn')" />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Timezone" label-for="timezone">
              <b-form-select v-model="item.timezone" :state="state('timezone')">
                <b-form-select-option v-for="tz in timezones" :key="tz" :value="tz">
                  {{ tz }}
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Street Address" label-for="street">
              <b-form-input id="street" v-model="item.street" :state="state('street')" type="text" placeholder="Street aadress and building number" />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="City" label-for="city">
              <b-form-input id="city" v-model="item.city" :state="state('city')" type="text" placeholder="City" />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Zip Code" label-for="zip">
              <b-form-input id="zip" v-model="item.zip" :state="state('zip')" type="text" placeholder="Zip" />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="State" label-for="state">
              <b-form-input id="state" v-model="item.state" :state="state('state')" type="text" placeholder="State" />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Country" label-for="country">
              <b-form-input id="country" v-model="item.country" :state="state('country')" type="text" placeholder="Country (ISO 3166 country code)" />
            </b-form-group>
          </template>
        </blueprint-data>
      </b-card>
    </div>
  </div>
</template>

<script>
import Timepicker from '../../../components/time-picker.vue';
import moment from 'moment-timezone';

export default {
  components: { Timepicker },
  data () {
    return {
      newDefaultValues: {
        timezone: moment.tz.guess(),
        checkOut: `12:00`,
        checkIn: `14:00`,
      },
      address: null,
      timezones: moment.tz.names(),
      fields: [
        {
          key: 'name',
          sortable: true,
        },
        {
          key: 'address',
          sortable: false,
          slot: true,
        },
      ],
    };
  },
};
</script>
